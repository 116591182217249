import Service from "../Service";
import Vue from "vue";
const resource = "frzcoolingchamber/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    /* getUrlSearchTunnelMaturation() {
        return Vue.prototype.$http.defaults.baseURL + "/Tunnel/list";
    }, */

};