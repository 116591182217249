import Service from "../Service";
const resource = "/maturationtunnelpallet/";

export default {
    saveEnvironment(obj, requestID) {
        return Service.post(resource + "saveEnvironment", obj, {
            params: { requestID: requestID },
        });
    }, 

    saveCalifornian(obj, requestID) {
        return Service.post(resource + "saveCalifornian", obj, {
            params: { requestID: requestID },
        });
    },

    saveChamber(obj, requestID) {
        return Service.post(resource + "saveChamber", obj, {
            params: { requestID: requestID },
        });
    },

    listStateMaturation(requestID) {
        return Service.post(resource + "listStateMaturation", "", {
            params: { requestID: requestID },
        });
    },

    listMovimients(obj, requestID) {
        return Service.post(resource + "listMovimients", obj, {
            params: { requestID: requestID },
        });
    },

    getMtp(LlpID, requestID) {
        return Service.post(resource + "llp", {}, {
            params: { LlpID: parseInt(LlpID), requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    listChamber(obj, requestID) {
        return Service.post(resource + "listChamber", obj, {
            params: { requestID: requestID },
        });
    },

    ChangeCustomerGroup(obj, requestID) {
        return Service.post(resource + "ChangeCustomerGroup", obj, {
            params: { requestID: requestID },
        });
    },
    getItemsTunnelTable(obj, requestID) {
        return Service.post(resource + "GetTunnelListTable", obj, {
            params: { requestID: requestID },
        });
    },
    getItemsAmbientTable(obj, requestID) {
        return Service.post(resource + "GetAmbientListTable", obj, {
            params: { requestID: requestID },
        });
    },
    getItemsCalifornianTable(obj, requestID) {
        return Service.post(resource + "GetCalifornianListTable", obj, {
            params: { requestID: requestID },
        });
    },
    getItemsChamberTable(obj, requestID) {
        return Service.post(resource + "GetChamberListTable", obj, {
            params: { requestID: requestID },
        });
    },
    /* getItemsTunnel(obj, requestID) {
        return Service.post(resource + "GetTunnelList", obj, {
            params: { requestID: requestID },
        });
    }, */

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },



    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },




    reentry(obj, requestID) {
        return Service.post(resource + "reentry", obj, {
            params: { requestID: requestID },
        });
    },

    reentrylist(requestID) {
        return Service.post(resource + "reentrylist", {}, {
            params: { requestID: requestID },
        });
    },


    editWeight(obj, requestID) {
        return Service.post(resource + "editWeight", obj, {
            params: { requestID: requestID },
        });
    },

    palletsinside(requestID) {
        return Service.post(resource + "palletsinside", {}, {
            params: { requestID: requestID },
        });
    },

    GetKgMaduration(obj, requestID) {
        return Service.post(resource + "GetKgMaduration", obj, {
            params: { requestID: requestID },
        });
    },


    beginprocessgroup(obj, requestID) {
        return Service.post(resource + "beginprocessgroup", obj, {
            params: { requestID: requestID },
        });
    },


    palletinsidecalifornian(obj, requestID) {
        return Service.post(resource + "palletinsidecalifornian", obj, {
            params: { requestID: requestID },
        });
    },


    palletinsideambient(obj, requestID) {
        return Service.post(resource + "palletinsideambient", obj, {
            params: { requestID: requestID },
        });
    },


    changeHour(obj, requestID) {
        return Service.post(resource + "changeHour", obj, {
            params: { requestID: requestID },
        });
    },

    verificarentry(obj, requestID) {
        return Service.post(resource + "verificarentry", obj, {
            params: { requestID: requestID },
        });
    },

    tonsinDay(PalletID, pallet, requestID)
    {
        return Service.post(resource + "tonsinDay", {},{
            params: { requestID: requestID,
                      PalletID: PalletID
                     , pallet: pallet},
        });
    },

    saveChangeTunnel(obj, requestID) {
        return Service.post(resource + "savechangetunnel", obj, {
            params: { requestID: requestID },
        });
    },

    saveChangeChamber(obj, requestID) {
        return Service.post(resource + "savechangechamber", obj, {
            params: { requestID: requestID },
        });
    },

    saveCalifornianChange(obj, requestID) {
        return Service.post(resource + "savechangecalifornian", obj, {
            params: { requestID: requestID },
        });
    },

    saveChangeEnvironment(obj, requestID) {
         return Service.post(resource + "savechangeenvironment", obj, {
            params: { requestID: requestID },
        });
    },

    saveTimeDate(obj, requestID) {
        return Service.post(resource + "savetimedate", obj, {
           params: { requestID: requestID },
       });
   },
};