<template>
	<v-card>
		<s-toolbar
			dark
			color="primary"
			:label="tunnel.FtmDescription"
			close
			@close="closeDetail"
		></s-toolbar>

		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>Scanner QR</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-container>
							<s-scanner-qr
								:config="configScann"
								autofocus
								return-object
								@onValue="onValue($event)"
								@onData="onData($event)"
							></s-scanner-qr>
							<v-row align="center" justify="center">
								<b>{{info}}</b>
							</v-row>
						</v-container>
						<v-card-text class="pb-5">
							<v-container>
								<v-row align="center" justify="center">
									<v-col cols="12" lg="4" md="12" sm="12">
										<s-text 
											v-model="item.MtpTemperatureBegin"
											label="Temperatura Entrada"
											number
										></s-text>
									</v-col>
									<v-col cols="12" lg="4" md="12" sm="12">
										<s-text 
											v-model="item.MtpEthyleneHour"
											label="Horas Etileno"
											number
										></s-text>
									</v-col>
									<v-col cols="12" lg="4" md="12" sm="12" align="center" justify="center">
										<v-btn
											@click="save()"
											color="primary"
											fab
											x-large
											dark
										>
											<v-icon>fas fa-archway</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>En Túnel</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col
								cols="6"
								lg="2"
								md="4"
								sm="4"
								v-for="item in palletsInside"
								:key="item.MtpID"
							>
								<v-card height="130" width="150" max-width="150" style="margin:auto">
									<v-card-text class="justify-center">
										<b>{{ "P - " + item.RcfID + ' - ' + item.RcfNumberHarvestGuide}}</b>
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											rounded
											color="primary"
											small
											@click="pickUpPallet(item)"
										>
											<v-icon>
												fas fa-truck-pickup
											</v-icon>
										</v-btn>
										
									</v-card-actions>
									<v-card-subtitle>
										<b>{{item.SecCreate}}</b>
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-model="dialog"
			persistent
			width="500"
		>
			<v-card>
				<s-toolbar
					dark
					color="primary"
					label="Salida de Pallet"
					close
					@close="dialog = false"
					save
					@save="save()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="12" lg="12" md="12" sm="12">
							<s-text 
								v-model="item.MtpTemperatureEnd"
								label="Temperatura Salida"
								number
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import SSelectTunnelMaturation from '../../../../components/FrozenProduction/Maturation/SSelectTunnelMaturation.vue';
import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService";

	export default {
		components: { 
			SSelectTunnelMaturation 
		},

		props: {
			tunnel: null,
			TypeMaturation:{
				type: Number,
				default: 1
			}
		},

		data() {
			return {
				dialog: false,
				palletsInside: [],
				configScann: {},
				info:"",
				item: {},
				codeqr: {}
			}
		},

		methods: {
			save()
			{
				if(this.item.isEdit == undefined){
					
					if(this.item.MtpTemperatureBegin == ""){
						this.$fun.alert("Ingrese temperatura", "warning");
						return;
					}

					if(this.item.MtpEthyleneHour == ""){
						this.$fun.alert("Ingrese temperatura", "warning");
						return;
					}

					this.item.LlpID = parseInt(this.codeqr[0]);
					this.item.FtmID = this.tunnel.FtmID;
					this.item.TypeMaturation = this.TypeMaturation;
					this.item.MtpStatus = 1;
					this.item.SecStatus = 1;
					this.item.UsrCreateID = this.$fun.getUserID();
					this.item.UsrUpdateID = this.$fun.getUserID();
				}
				else
				{
					this.item.MtpStatus = 2;
					this.item.UsrUpdateID = this.$fun.getUserID();
				}
				
				console.log('save ', this.item);



				/* this.$fun.alert("¿Seguro de guardar? ", "question")
				.then(resp => {
					if(resp.value){
						_sMaturationTunnelPalletService.save(this.item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Ingresado Correctamente", "success");
								this.item.MtpTemperatureBegin = "";
								this.item.MtpEthyleneHour = "";
								this.getPalletsInside();
								this.dialog = false;
							}
						})
					}
				}) */
				
			},

			pickUpPallet(item)
			{
				this.$fun.alert("¿Seguro de sacar pallet?", "question")
				.then(resp => {
					if(resp.value){
						this.dialog = true;
						item.isEdit = true;
						this.item = item;
					}
				})
			},

			closeDetail() {
				this.$emit("closeDetail");
			},

			onValue(val) {
				if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {
					console.log('si tiene datos');
				}
			},

			onData(val) {
				let codeqrarray = val.split(",");
				this.codeqr = codeqrarray;
				this.info = "# Jabas: " + codeqrarray[1] + " || " + codeqrarray[2]  + " || " + codeqrarray[3] + " || " + codeqrarray[4] + " || " + codeqrarray[5] + " || " + codeqrarray[6]
			},

			getPalletsInside()
			{
				_sMaturationTunnelPalletService
					.list(
						{ FtmID: this.tunnel.FtmID, TypeMaturation: this.TypeMaturation, MtpStatus: 1 },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.palletsInside = resp.data;
							this.palletsInside.forEach(element => {
								element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
							});
							console.log("palletsInside ", this.palletsInside);
						}
					});
			}
		},

		created () {
			this.getPalletsInside();
		},
	}
</script>