<template>
	<div style="padding-top: -2px; margin-top: -2px">
		<s-select-generic
			ref="searchtunnelmaturation"
			autocomplete
			class="text"
			itemtext="FtmDescription"
			itemvalue="FtmID"
			:disabled="disabled"
			id="FtmID"
			:value="value"
			:return-object="returnObject"
			@input="input($event)"
			:config="config"
			:label="label"
			:full="full"
			:autofocus="autofocus"
			:text="text"
			:clearable="clearable"
			:noDefault="noDefault"
		>
		</s-select-generic>
	</div>
</template>

<script>
import _sTunnelMaturationService from "@/services/FrozenProduction/TunnelMadurationService";
export default {
  	name: "SelectTunnelMaturation",
	props: {
		autofocus: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		value: null,
		text: { 
			type: String, 
			default: "" 
		},
		label: {
			type: String,
			default: "",
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		centercost: {
			type: Boolean,
			default: false,
		},
		noDefault: {
			type: Boolean,
			default: false,
		},
		typeCost: {
			type: Number,
			default: 0,
		},
		FtmID: {
			type: Number,
			default: 3,
		},
		full: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		item: 0,
		val: "",
		items: [],
		config: {},
	}),

	watch: {
		FtmID() {
			this.config.params.FtmID = this.FtmID;
		},
		value(value) {
			this.item = value;
		},
	},

	methods: {
		focus() {
			this.$refs.sarea.focus();
		},
		input(val) {
			this.$emit("input", val);
		},
	},

	created() {
		this.config = {
			url: _sTunnelMaturationService.getUrlSearchTunnelMaturation(),
			title: this.label,
			params: {
				FtmID: this.FtmID,
				search: "",
				requestID: this.$fun.getUserID(),
			},
		};
	},
};
</script>
